$(document).ready(function () {

	if (winWT < 1023) {


	}

	


});